import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useMemo
} from 'react';
import { useMount } from 'react-use';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/react';

import { Copy, ExternalLinks, Locale, ResultCollections } from '@utils/api';
import Meta from '@components/Meta';
import { useLocalStorage } from 'react-use';
import ErrorLandscape from '@components/LandscapeError';
import useMobileDetect from 'src/hooks/useMobileDetect';

export type Page = {
  copy: (key: string) => string;
  externalLink: (key: string) => string;
  collections: ResultCollections;
  region: string;
  locale: Locale;
  cookies: {
    functional: boolean;
    analytics: boolean;
    terms: boolean;
    setAccepted: (
      key: 'functional' | 'analytics' | 'terms' | 'cookies',
      value: boolean
    ) => void;
    showPopup: boolean;
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

export const PageContext = createContext<Page>({
  copy: () => '',
  externalLink: () => '',
  collections: {},
  locale: null,
  cookies: null,
  region: null
});

type PageProps = {
  locale: Locale;
  copy: Copy;
  externalLinks: ExternalLinks;
  collections: ResultCollections;
  children: React.ReactNode;
};

const REGION_MAPPING: Record<Locale, string> = {
  [Locale.ENGLISH_UK]: 'gb',
  [Locale.ENGLISH_US]: 'us',
  [Locale.SPANISH]: 'es',
  [Locale.GERMAN]: 'de',
  [Locale.ITALIAN]: 'it',
  [Locale.FRENCH]: 'fr',
  [Locale.TURKISH]: 'tr'
};

const IGNORE_SENDING_MISSING_COPY_KEY = ['generic.cookies.analytics.copy2'];

const Page = ({
  children,
  locale,
  copy,
  externalLinks,
  collections
}: PageProps) => {
  const router = useRouter();
  const [acceptedTerms, setAcceptedTerms] = useLocalStorage(
    'accept-terms',
    false
  );
  const [acceptedFunctionalCookies, setFunctionalCookies] = useLocalStorage(
    'accept-functional',
    false
  );
  const [acceptedAnalyticsCookies, setAnalyticsCookies] = useLocalStorage(
    'accept-analytiics',
    false
  );
  const [showCookiePopup, setShowCookiePopup] = useState(false);
  const [keysOnly, setKeysOnly] = useState(false);
  const [isMobileLandscape, setIsMobileLandscape] = useState(false);
  const isMobileDevice = useMobileDetect();

  const acceptCookies = useCallback(
    (key: 'functional' | 'analytics' | 'terms' | 'cookies', value: boolean) => {
      if (key === 'functional' || key === 'cookies') {
        setFunctionalCookies(value);
      }
      if (key === 'analytics' || key === 'cookies') {
        setAnalyticsCookies(value);
      }
      if (key === 'terms' || key === 'cookies') {
        setAcceptedTerms(value);
      }
    },
    [setFunctionalCookies, setAnalyticsCookies, setAcceptedTerms]
  );

  const copyGetter = useCallback(
    (key: string) => {
      if (!copy[key] && !IGNORE_SENDING_MISSING_COPY_KEY.includes(key)) {
        console.error(`Copy key not found: ${key}`);
        if (window.location.host.includes('staging')) {
          Sentry.captureMessage(`Copy key not found '${key}' for ${locale}`);
        }
      }
      return keysOnly ? key : copy[key];
    },
    [keysOnly, copy, locale]
  );

  const region = useMemo(() => {
    const qRegion = Array.isArray(router.query?.region)
      ? router.query?.region[0]
      : router.query?.region || '';
    const regionUnsafe = qRegion || REGION_MAPPING[locale];
    return regionUnsafe.slice(0, 2).toLowerCase();
  }, [router, locale]);

  const externalLink = useCallback(
    (key: string) => {
      Sentry.addBreadcrumb({
        type: 'http',
        category: 'external.link',
        message: `Trying to route to external link ${key} in ${locale}-${region}`,
        data: {
          key,
          locale,
          region
        }
      });

      if (!externalLinks[key]) {
        throw new Error(
          `Trying to get external link ${key} that does not exist...`
        );
      }

      try {
        // We don't have links for this region so try if the locale is a region too...
        if (!externalLinks[key][region] && externalLinks[key][locale]) {
          Sentry.captureMessage(
            `External link '${key}' not found for ${locale}-${region}`
          );
          return (
            externalLinks[key][locale][locale] ||
            externalLinks[key][locale].default
          );
        }

        return (
          externalLinks[key][region][locale] ||
          externalLinks[key][region][region] ||
          externalLinks[key][region].default
        );
      } catch (e) {
        // Make sure to send this to Sentry
        Sentry.captureMessage(
          `External link '${key}' not found for ${locale}-${region}`
        );

        // Just send the english link as a fallback for now...
        return externalLinks[key]['gb']['en'];
      }
    },
    [externalLinks, region, locale]
  );

  useEffect(() => {
    const keydown = (evt: KeyboardEvent) => {
      if (
        evt.ctrlKey &&
        evt.shiftKey &&
        (evt.key === 'T' || evt.keyCode === 88)
      ) {
        setKeysOnly(k => !k);
        evt.preventDefault();
      }
    };

    window.addEventListener('keydown', keydown);

    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, []);

  useEffect(() => {
    const detect = () => {
      setIsMobileLandscape(
        isMobileDevice &&
          typeof window !== 'undefined' &&
          window.matchMedia('screen and (orientation:landscape)').matches
      );
    };
    window.addEventListener('resize', detect);
    return () => {
      window.removeEventListener('resize', detect);
    };
  });

  useMount(() => {
    // for dev purposes show the copy so we
    if (process.env.NODE_ENV === 'development') {
      console.dir(copy);
    }

    try {
      const accepted =
        JSON.parse(localStorage.getItem('accept-functional')) || false;
      if (!accepted) {
        setShowCookiePopup(true);
      }
    } catch (e) {
      console.warn('Localstorage not supported, always showing cookie popup');
    }
  });

  if (isMobileLandscape) {
    return (
      <ErrorLandscape message={copyGetter('generic.landscapewarning.title')} />
    );
  }
  return (
    <PageContext.Provider
      value={{
        copy: copyGetter,
        externalLink,
        collections,
        region,
        locale,
        cookies: {
          terms: acceptedTerms,
          analytics: acceptedAnalyticsCookies,
          functional: acceptedFunctionalCookies,
          setAccepted: acceptCookies,
          showPopup: showCookiePopup,
          setShowPopup: setShowCookiePopup
        }
      }}
    >
      <Meta copy={copy} locale={locale} />
      {children}
    </PageContext.Provider>
  );
};

export default Page;
