import { FlexProps } from '@chakra-ui/react';

export const landscapeWrapper: FlexProps = {
  position: 'fixed' as const,
  top: '0',
  left: '0',
  zIndex: '100',
  textAlign: 'center' as const,
  justifyContent: 'center',
  alignItems: 'center' as const,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0 , 0.95)'
};
export const landscapeIcon = {
  maxWidth: '85px',
  margin: '0 auto 30px'
};
export const landscapeErrorText = {
  fontSize: '0.9rem',
  lineHeight: '1.8',
  color: '#848484',
  fontFamily: 'copy'
};
