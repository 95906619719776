import create from 'zustand';

import type { Locale, QuizData } from '@utils/api';
import type {
  NikeActivityFinderAnswers,
  NikeActivityFinderQuestions,
  NikeActivityFinderResults
} from 'src/queries/directus';
import { sendEvent } from '@utils/analytics';
import { compare, compare2, getTags } from '@utils/tags';

export type Answer = {
  key: string;
  text: string;
  tags: string[];
  id: string;
};

export type APIAnswer = QuizData['questions'][0]['answers'][0];

export type Response = {
  questionId: string;
  answer: Answer;
};

export const convertAnswer = (answer: APIAnswer): Answer => {
  return {
    key: `A-${answer.id}`,
    text: answer.name,
    tags: Array.isArray(answer.tags) ? answer.tags : [],
    id: answer.id
  };
};

const getNextQuestionInfo = (
  map: QuizData['questionMaps'][0],
  answers: QuizData['answers']
) => {
  //1. find most recent answer node
  const answerIndex = map.Sequences.findIndex(
    s =>
      s.item.__typename === 'NikeActivityFinderAnswers' &&
      s.item.id === answers[answers.length - 1].id
  );

  //2. find all remaining Questions and answers
  const remaining = map.Sequences.slice(answerIndex);

  //3. find next question
  const nextQuestion = remaining.find(
    s => s.item.__typename === 'NikeActivityFinderQuestions'
  );

  if (!nextQuestion) {
    return { nextQuestion: null, progress: 1 };
  }

  //4. calculate progress in sequence
  const sequenceQuestions = map.Sequences.filter(
    s => s.item.__typename === 'NikeActivityFinderQuestions'
  );
  const nextQuestionIndex = sequenceQuestions.indexOf(nextQuestion);
  const progress = (1 / (sequenceQuestions.length + 1)) * nextQuestionIndex;

  return {
    nextQuestion: nextQuestion?.item,
    progress
  };
};

export const filterResultsByTags = (
  results: QuizData['results'],
  locale: string,
  tags: string[]
) =>
  results.filter(
    r =>
      tags.length === 0 ||
      (Array.isArray(r.tags) &&
        r.tags.filter(t => tags.includes(t)).length === tags.length &&
        (!locale ||
          r.translations.findIndex(
            t =>
              t.NikeActivityFinderLanguages_code.code === locale &&
              t.deeplink &&
              t.deeplink !== ''
          ) > -1))
  );

type QuizStore = {
  quizData: QuizData | null;
  locale: Locale | null;
  setupIfRequired: (locale: Locale, quizData: QuizData) => void;
  setupWithResults: (
    locale: Locale,
    quizData: QuizData,
    initialAnswerIds: string[]
  ) => void;
  getIsReady: () => boolean;

  selectedAnswer: NikeActivityFinderAnswers | null;
  setSelectedAnswer: (selectedAnswer: NikeActivityFinderAnswers) => void;
  submitSelectedAnswer: () => boolean;

  responses: Response[];
  currentQuestion: string;
  progress: number;

  setResponses: (responses: Response[], dry?: boolean) => boolean | string;
  addResponse: (response: Response) => boolean;
  popResponse: () => boolean;

  getTags: () => string[];
  getFilteredResults: () => NikeActivityFinderResults[];
  getFilteredTags: () => any[];
  getFilteredAnswers: (
    question: NikeActivityFinderQuestions,
    customTags?: string[]
  ) => NikeActivityFinderQuestions;

  getAllResults: () => NikeActivityFinderResults[];
  getAnswerIds: () => string[];

  reset: () => void;

  getResultMapping: () => {
    path: string[];
    count: number;
    list: NikeActivityFinderResults[];
  }[];
};
export const useQuizStore = create<QuizStore>((set, get) => ({
  quizData: null,
  locale: null,
  setupIfRequired: (locale, quizData) => {
    if (locale !== get().locale) set(() => ({ locale }));
    if (quizData !== get().quizData) set(() => ({ quizData }));
  },
  setupWithResults: (locale, quizData, answerIds) => {
    if (answerIds.length > 0) {
      get().setupIfRequired(locale, quizData);

      // Set responses
      if (answerIds && answerIds.length > 0 && get().responses.length === 0) {
        const myAnswers = quizData.answers.filter(a =>
          answerIds.includes(a.id)
        );
        const responses: Response[] = myAnswers.map(a => ({
          questionId: a.prevQuestion.id,
          answer: convertAnswer(a)
        }));

        get().setResponses(responses);
      }
    }
  },
  getIsReady: () => !!(get().quizData && get().locale),

  selectedAnswer: null,
  setSelectedAnswer: selectedAnswer => set(() => ({ selectedAnswer })),
  submitSelectedAnswer: () => {
    const { selectedAnswer, currentQuestion, addResponse } = get();
    if (!selectedAnswer) return true;
    sendEvent('answer', {
      answer_name: selectedAnswer.name,
      question_id: currentQuestion
    });
    return addResponse({
      questionId: currentQuestion,
      answer: convertAnswer(selectedAnswer)
    });
  },

  responses: [],
  currentQuestion: '1',
  progress: 0,
  setResponses: (responses, dry = false) => {
    // Whatever happens, we're no longer needing the answer selection
    set(() => ({ selectedAnswer: null }));

    if (!responses.length) {
      set(() => ({ responses, currentQuestion: '1', progress: 0 }));
      return true;
    }

    const { quizData, getFilteredResults } = get();

    if (!quizData) {
      throw new Error('Expected quiz data to be set by now');
    }

    const { questionMaps, conditions } = quizData;

    const map = questionMaps.find(q =>
      responses.every(
        response =>
          q.Sequences.find(
            s =>
              s.item.__typename === 'NikeActivityFinderQuestions' &&
              s.item.id === response.questionId
          ) &&
          q.Sequences.find(
            s =>
              s.item.__typename === 'NikeActivityFinderAnswers' &&
              s.item.id === response.answer.id
          )
      )
    );

    // For some reason we don't have a map...
    if (!map) {
      if (!dry) {
        set(() => ({ progress: 1 }));
      }
      return false;
    }

    let { nextQuestion, progress } = getNextQuestionInfo(
      map,
      responses.map(a => a.answer)
    );

    if (!dry) {
      set(() => ({ responses }));
    }

    // Use conditional logic
    conditions.forEach(condition => {
      const myTags = responses.map(r => r.answer.tags).flat();

      // ensuring this conditon works for this language
      if (condition.translations.length > 0) {
        const local = condition.translations.find(
          t => t.NikeActivityFinderLanguages_code.code === get().locale
        );
        if (local && local.Enabled === false) {
          return;
        }
      }

      // Go straight to results now
      if (
        compare(myTags, getTags(condition.tags)) &&
        condition.action === 'goto_results'
      ) {
        nextQuestion = null;
      }

      // Skip the next question if needed
      if (
        compare2(getTags(condition.tags), myTags) &&
        condition.action === 'skip_question' &&
        nextQuestion &&
        condition.Question &&
        condition.Question.id === nextQuestion.id
      ) {
        const id = 'id' in nextQuestion && nextQuestion.id;
        const skipQuestion = quizData.questions.find(a => a.id === id);
        const nextAnswer = skipQuestion.answers[0];

        // just give the first answer so we know where to go to instead...
        // but we don't save this to state, we just emulate it
        const next = getNextQuestionInfo(
          map,
          [
            ...responses,
            {
              questionId: nextQuestion.id,
              answer: nextAnswer
            }
          ].map(a => a.answer)
        );

        nextQuestion = next.nextQuestion;
        progress = next.progress;
      }
    });

    if (!nextQuestion) {
      if (!dry) {
        set(() => ({ progress: 1 }));
      }
      return false;
    }

    if (!dry) {
      set(() => ({
        currentQuestion: nextQuestion.id,
        selectedAnswer: null,
        progress
      }));
    } else {
      return nextQuestion.id;
    }

    return true;
  },
  addResponse: response =>
    !!get().setResponses(get().responses.concat(response)),
  popResponse: () => !!get().setResponses(get().responses.slice(0, -1)),

  reset: () => {
    set({
      selectedAnswer: null,
      responses: [],
      currentQuestion: '1',
      progress: 0
    });
  },

  getTags: () =>
    get()
      .responses.map(r => r.answer.tags)
      .filter(t => t)
      .flat(2),

  getAllResults: () => {
    return get().quizData.results;
  },

  getAnswerIds: () => get().responses.map(a => a.answer.id),

  getFilteredResults: () => {
    const { quizData, locale, getTags } = get();
    if (!quizData || !locale) {
      throw new Error('Expected quizData and locale to be set');
    }

    return filterResultsByTags(quizData.results, locale, getTags());
  },

  getFilteredAnswers: (
    question: NikeActivityFinderQuestions,
    customTags?: string[]
  ) => {
    const tags = customTags || get().getTags();
    return {
      ...question,
      answers: question.answers.filter(a => {
        if (a.hide_condition && Array.isArray(a.hide_condition)) {
          let hide = false;

          a.hide_condition.forEach(cond => {
            const condTags = cond.condition.split(',') as string[];

            const forLanguage =
              !cond.language_code || cond.language_code === get().locale;

            if (forLanguage && condTags.every(t => tags.includes(t))) {
              hide = true;
            }
          });

          return !hide;
        }

        return !a.categoryFilter || tags.includes(a.categoryFilter);
      })
    };
  },

  getFilteredTags: () => {
    return get()
      .getFilteredResults()
      .map(r => r.tags)
      .flat();
  },

  getResultMapping: () => {
    const { setResponses, currentQuestion, quizData } = get();

    const startQuestion = quizData.questions.find(
      q => q.id === currentQuestion
    );

    const answers: string[][] = [];
    const results: {
      path: string[];
      count: number;
      list: NikeActivityFinderResults[];
    }[] = [];

    // Recursive logic
    const recursive = (responses: Response[]) => {
      let nextQuestion: string | boolean;

      try {
        nextQuestion = setResponses(responses, true);
      } catch (error) {
        console.error('error while trying to get responses for', {
          responses,
          error
        });
      }

      if (!nextQuestion) {
        answers.push(responses.map(r => r.answer.id));
      } else if (typeof nextQuestion === 'string') {
        const next = get().getFilteredAnswers(
          quizData.questions.find(q => q.id === nextQuestion),
          responses.map(r => r.answer.tags).flat()
        );
        next.answers.forEach(a => {
          recursive([
            ...responses,
            { questionId: next.id, answer: convertAnswer(a) }
          ]);
        });
      }
    };

    // Start
    get()
      .getFilteredAnswers(startQuestion)
      .answers.forEach(a => {
        recursive([{ questionId: startQuestion.id, answer: convertAnswer(a) }]);
      });

    answers.forEach(answerIds => {
      const myAnswers = answerIds.map(id =>
        quizData.answers.find(a => a.id === id)
      );
      const responses: Response[] = myAnswers.map(a => ({
        questionId: a.prevQuestion.id,
        answer: convertAnswer(a)
      }));

      const tags = responses.map(r => r.answer.tags).flat();
      const list = filterResultsByTags(quizData.results, get().locale, tags);

      results.push({
        path: responses.map(r => r.answer.text),
        count: list.length,
        list
      });
    });

    return results;
  }
}));
