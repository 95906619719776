export const getTags = (tags: any): string[] => {
  return Array.isArray(tags) ? tags : [];
};

const REMOVE_LIST = ['<30min', '30-60min', '1hr+', 'dancing', 'dance'];

export const tagsToString = (tags: unknown, copy: (key: string) => string) => {
  return getTags(tags)
    .filter(t => !!t && REMOVE_LIST.includes(t) === false)
    .map(t => copy(`generic.tags.${t}`))
    .join(', ');
};

export const compare = (tags1: string[], tags2: string[]) => {
  if (
    !Array.isArray(tags1) ||
    !Array.isArray(tags2) ||
    tags1.length !== tags2.length
  ) {
    return false;
  }

  // .concat() to not mutate arguments
  const arr1 = tags1.concat().sort();
  const arr2 = tags2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const compare2 = (tags: string[], tags2: string[]) => {
  return tags.every(t => tags2.includes(t));
};
