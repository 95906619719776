import {
  Box,
  Flex,
  keyframes,
  Text,
  usePrefersReducedMotion
} from '@chakra-ui/react';
import { landscapeIcon, landscapeWrapper, landscapeErrorText } from './style';
import { ReactComponent as TurnLandscape } from '../../assets/icons/turnPortrait.svg';
import { FC } from 'react';
interface ErrorLandscapeProps {
  message: string;
}
const ErrorLanscape: FC<ErrorLandscapeProps> = ({ message }) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const popGl = keyframes`
    0% { top: 100% } 100% { top: 0 }`;
  const popupanim = prefersReducedMotion ? undefined : `${popGl} .3s forwards`;
  return (
    <Flex {...landscapeWrapper} animation={popupanim}>
      <Box>
        <Box {...landscapeIcon}>
          <TurnLandscape />
        </Box>
        <Text {...landscapeErrorText}>{message}</Text>
      </Box>
    </Flex>
  );
};
export default ErrorLanscape;
